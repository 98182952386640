import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import { cacheKeys } from "@app/constants";
import { fetchSelf, queryClient } from "@app/utils/query";

export const JWT_STORAGE_KEY = "JWT_STORAGE_KEY";

export function getAuthHeaders() {
  const jwt = getCurrentJWT();
  if (!jwt) throw new Error("No JWT found");
  return { Authorization: `bearer ${jwt}` };
}

export function getCurrentJWT() {
  return localStorage.getItem(JWT_STORAGE_KEY);
}

export function setCurrentJWT(token: string | null) {
  if (!token) return localStorage.removeItem(JWT_STORAGE_KEY);
  localStorage.setItem(JWT_STORAGE_KEY, token);
}

export function isSignedIn() {
  return !!getCurrentJWT();
}

export const useIsSignedIn = (onAuthFailure = (error: any) => {}) => {
  return useQuery({
    queryKey: cacheKeys.isSignedIn,
    queryFn: async () => {
      try {
        const jwt = getCurrentJWT();
        const hasJWT = !!jwt;
        if (!hasJWT) return false;
        // Test the JWT - TODO reconsider refetchInterval and staleTime
        await fetchSelf();
        return true;
      } catch (error) {
        console.warn("useIsSignedIn Error", error);
        if (error instanceof AxiosError) {
          // Delete the JWT if it is invalid
          const statusCode = (error as AxiosError).response?.status;
          if (statusCode === 401) {
            setCurrentJWT(null);
            queryClient.invalidateQueries();
            onAuthFailure(error);
          }
        }
        return false;
      }
    },
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60,
  });
};
