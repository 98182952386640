import axios, { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { API_URL, cacheKeys } from "@app/constants";
import { Build, OffsetPaginatedResponse, PageAndSortParams } from "@app/types";
import { castArrayObjectDates } from "@app/utils/dates";
import { getAuthHeaders } from "./useIsSignedIn";

export interface BuildsQueryProps extends PageAndSortParams {
  projectId: string;
}

export type BuildsQueryResponse = OffsetPaginatedResponse<Build>;

export async function queryBuilds({
  projectId,
  ...pageAndSortParams
}: BuildsQueryProps): Promise<BuildsQueryResponse> {
  try {
    if (!projectId) throw new Error("projectId is required");
    const headers = getAuthHeaders();
    const url = `${API_URL}/projects/${projectId}/builds`;
    const response = await axios.get(url, {
      headers,
      params: pageAndSortParams,
    });
    return {
      ...response.data,
      data: castArrayObjectDates<Build>(response.data.data),
    };
  } catch (error) {
    console.warn("queryBuilds Error", error);
    throw error;
  }
}

export const useBuilds = (
  props: BuildsQueryProps
): UseQueryResult<BuildsQueryResponse, AxiosError> => {
  const queryResult = useQuery<BuildsQueryResponse, AxiosError>({
    queryKey: cacheKeys.builds(props),
    queryFn: async () => queryBuilds(props),
  });

  return queryResult;
};
