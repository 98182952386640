import React, { useState } from "react";
import { Platform } from "@app/types";
import {
  CredentialRow,
  Spinner,
  StatusMessage,
  EmptyState,
} from "@app/components";

interface Props {
  credentials: any;
  platform: Platform;
  isLoading: boolean;
  isSuccess: boolean;
  missingMessage?;
}

const CredentialList = ({
  credentials,
  platform,
  isLoading,
  isSuccess,
  missingMessage,
}: Props) => {
  const [downloadError, setDownloadError] = useState<undefined | string>();

  if (isLoading) return <Spinner />;

  return (
    <>
      {downloadError && (
        <StatusMessage type="success" className="_mb10">
          {downloadError}
        </StatusMessage>
      )}

      {credentials?.map((cred) => (
        <CredentialRow
          owner="user"
          credential={cred}
          onDownloadError={setDownloadError}
        />
      ))}

      {isSuccess && credentials?.length === 0 && (
        <EmptyState message="No credentials found" className="_mb15">
          <div>{missingMessage}</div>
        </EmptyState>
      )}
    </>
  );
};

export { CredentialList };
