import React from "react";

import {
  FormControl,
  PhoneOs,
  Button,
  Download,
  Credential,
} from "@app/components";
import { getErrorMessage } from "@app/utils/errors";
import { useDownloadCredential } from "@app/utils/hooks";
import {
  CredentialsType,
  Platform,
  ProjectCredential,
  UserCredential,
} from "@app/types";

type CredentialOwner = "project" | "user";
type AnyCredential = ProjectCredential | UserCredential;

interface CredentialRowProps {
  owner: CredentialOwner;
  credential: AnyCredential;
  onDownloadError?: (error: string) => void;
}

// Used on the User credential list screen and the Game credential list screen
const CredentialRow = ({
  owner,
  credential,
  onDownloadError,
}: CredentialRowProps) => {
  const { downloadCredential, isLoading: isDownloading } =
    useDownloadCredential();

  const handleDownloadPress = async () => {
    try {
      const downloadProps =
        owner === "project"
          ? { projectId: (credential as ProjectCredential).projectId }
          : {};

      await downloadCredential({
        ...downloadProps,
        credentialId: credential.id,
      });
    } catch (error) {
      console.warn(error);
      onDownloadError && onDownloadError(getErrorMessage(error));
    }
  };

  const { isActive, serialNumber, platform } = credential;

  return (
    <FormControl
      label={
        owner === "project"
          ? getProjectCredLabel(credential)
          : getUserCredLabel(credential)
      }
      className={`${owner}Credential`}
      icon={
        <div style={{ display: "flex" }}>
          <div
            className="formItem-credentialIcon"
            style={{ marginRight: "5px" }}
          >
            <Credential type={credential.type} size={16} />
          </div>
          <PhoneOs
            platform={platform}
            size={16}
            className={`${owner}Credential-os`}
          />
        </div>
      }
      altIcon={
        isActive && <div className={`${owner}Credential-isActive`}>Active</div>
      }
    >
      <div className="_flex _flex-center">
        <div className={`${owner}Credential-text`}>{serialNumber}</div>
        <Button
          size="sm"
          variant="dark-alt"
          className="_ml5"
          onClick={handleDownloadPress}
          isLoading={isDownloading}
          centeredLoader
        >
          <span className="_mr5">Download</span>
          <Download width={16} stroke="#95a0aa" />
        </Button>
      </div>
    </FormControl>
  );
};

const getProjectCredLabel = (cred: AnyCredential) => {
  switch (cred.platform) {
    case Platform.IOS:
      // Currently the only type of project cred for iOS
      return "Mobile Provisioning Profile";
    case Platform.ANDROID:
      return cred.type === CredentialsType.CERTIFICATE
        ? "Android Key Store"
        : "Service Account API Key";
    default:
      throw new Error(`Unknown platform: ${cred.platform}`);
  }
};

const getUserCredLabel = (cred: AnyCredential) => {
  switch (cred.platform) {
    case Platform.IOS:
      return cred.type === CredentialsType.CERTIFICATE
        ? "iOS Distribution Certificate"
        : "App Store Connect API Key";
    // Currently no user-credentials for other platforms
    default:
      throw new Error(`Unknown platform: ${cred.platform}`);
  }
};

export { CredentialRow };
