import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { API_URL, cacheKeys } from "@app/constants";
import { castArrayObjectDates } from "@app/utils/dates";
import { Project, PageAndSortParams } from "@app/types";

import { getAuthHeaders } from "./useIsSignedIn";

export interface ProjectsQueryResult {
  projects: Project[];
  pageCount: number;
}

export async function fetchProjects(
  params: PageAndSortParams,
  config?: AxiosRequestConfig
): Promise<ProjectsQueryResult> {
  try {
    const headers = getAuthHeaders();
    const { data } = await axios.get(`${API_URL}/projects`, {
      headers,
      params,
      ...config
    });
    return {
      projects: castArrayObjectDates<Project>(data.data),
      pageCount: data.pageCount
    };
  } catch (error) {
    console.warn("fetchProjects Error", error);
    throw error;
  }
}

export const useProjects = (
  params: PageAndSortParams = {}
): UseQueryResult<ProjectsQueryResult> => {
  return useQuery<ProjectsQueryResult, AxiosError>({
    queryKey: cacheKeys.projects(params),
    queryFn: () => fetchProjects(params),
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60
  });
};
