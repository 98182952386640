import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import { getAuthHeaders } from "@app/utils/query";
import { getErrorMessage, isAbortError } from "@app/utils/errors";
import { API_URL } from "@app/constants";
import {
  GoogleOAuthPersistTokenRequest,
  GoogleOAuthRedirectResponse,
  GoogleStatusResponse,
} from "@app/types";

export const GoogleRedirect = () => {
  const [error, setError] = useState<undefined | string>();

  // This is a hack to avoid double http request in dev
  // We should have an abortController and accept the issue in dev or something.
  // But that reliably breaks the oauth in dev
  const initialized = useRef(false);

  const handlePageLoad = async () => {
    try {
      const queryString = window.location.search;
      const query: GoogleOAuthRedirectResponse = Object.fromEntries(
        new URLSearchParams(queryString)
      );

      if (query.error) throw new Error(query.error);
      if (!query.code) throw new Error("Missing 'code' from query params");

      // Send the code to the backend - it will exchange for a token and save it
      const base = new URL("/", window.location.href).href;
      const redirectUri = `${base}google/redirect/`;
      const persistTokenRequest: GoogleOAuthPersistTokenRequest = {
        code: query.code,
        redirectUri,
      };

      const headers = getAuthHeaders();
      const opt = { headers };
      const { data } = await axios.post(
        `${API_URL}/me/google/connect`,
        persistTokenRequest,
        opt
      );
      const response: GoogleStatusResponse = data;
      // Redirect to the project android setup page - which is in the Docs
      const url = `/docs/android/?gameId=${response.projectId}#2-connect-shipthis-with-google`;
      const fullUrl = new URL(url, window.location.href).href;
      // Since it is in the docs we cant use navigate
      window.location.href = fullUrl;
    } catch (error) {
      if (isAbortError(error)) return;
      console.warn(error);
      setError(getErrorMessage(error));
    }
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      handlePageLoad().then(() => {
        //console.log("done");
      });
    }
  }, []);

  // TODO: better handling for errors
  // It could link back to the start of the oauth flow
  // Or show the button again
  // Or some message "please press back and try again"
  // Anything is better than this
  return (
    <>
      <h1>ShipThis Google Redirect</h1>
      {error && <h1 style={{ color: "red" }}>{error}</h1>}
    </>
  );
};
