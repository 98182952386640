import React from "react";
import { Terminal, Line, Span, Progress } from "@app/components/Terminal";
import { showToast } from "@app/utils/emitters";

const Typing = () => (
  <Terminal
    mode="dark"
    afterCopy={() => showToast("Text Copied.")}
    className="hero-terminal"
  >
    <Line className="nearwhite" postDelay={1000}>
      <Span postDelay={100} typeDelay={50} preDelay={1000}>
        npm
      </Span>
      <Span postDelay={0}> </Span>
      <Span postDelay={200} className="blue" typeDelay={50}>
        install -g
      </Span>
      <Span postDelay={0}> </Span>
      <Span postDelay={500} className="red" typeDelay={40}>
        shipthis
      </Span>
    </Line>
    <Line noPrompt type="progress">
      <Progress />
    </Line>
    <Line noPrompt typeDelay={0} postDelay={50}>
      added 231 packages in 6s
    </Line>
    <Line className="green" noPrompt typeDelay={0} postDelay={200}>
      ShipThis installed 😎
    </Line>
    <Line preDelay={2000} typeDelay={0} postDelay={0}>
      {" "}
    </Line>
    <Line className="nearwhite" postDelay={1000}>
      <Span postDelay={500} className="red" typeDelay={40}>
        shipthis
      </Span>
      <Span postDelay={0}> </Span>
      <Span postDelay={100} typeDelay={50} className="blue">
        login
      </Span>
    </Line>
  </Terminal>
);

export { Typing };
