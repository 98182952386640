import React, {
  JSXElementConstructor,
  ReactElement,
  useEffect,
  useRef,
  useState
} from "react";
import classnames from "classnames";

import { CopyThis } from "@app/components/Icons";
import { useIntersectionObserver } from "@app/utils/hooks";

interface Props extends React.ComponentProps<"div"> {
  mode?: "bright" | "dark";
  title?: string;
  afterCopy?: () => void;
  enableCopyContent?: boolean;
  waitForVisibility?: boolean;
}

const Terminal = ({
  title,
  mode = "dark",
  enableCopyContent = true,
  waitForVisibility = true,
  afterCopy,
  className,
  children
}: Props) => {
  const terminalRef = useRef<HTMLDivElement>(null);
  const [currentLine, setCurrentLine] = useState(0);

  const { isIntersecting: isVisible, ref: visibilityRef } =
    useIntersectionObserver({
      threshold: 0.5
    });

  const scrollToEnd = async () => {
    if (terminalRef.current)
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
  };

  const copyToClipboard = () => {
    if (terminalRef.current) {
      navigator.clipboard.writeText(terminalRef.current.innerText);
    }
    if (typeof afterCopy === "function") afterCopy();
  };

  const wait = waitForVisibility ? waitForVisibility && isVisible : true;

  useEffect(() => {
    scrollToEnd();
  }, [currentLine]);

  return (
    <div
      ref={visibilityRef}
      className={classnames("terminal", className, {
        "terminal--dark": mode === "dark",
        "terminal--bright": mode === "bright"
      })}
    >
      <div className="terminal-header">
        {title && title}
        {enableCopyContent && (
          <a className="terminal-copyThis" onClick={copyToClipboard}>
            <CopyThis />
          </a>
        )}
      </div>
      <div className="terminal-content" ref={terminalRef}>
        {React.Children.map(children, (child, i) =>
          React.cloneElement(
            child as ReactElement<any, string | JSXElementConstructor<any>>,
            {
              isActive: currentLine === i && wait,
              isDone: currentLine > i,
              onFinish: () => {
                setCurrentLine((index) => (index += 1));
              }
            }
          )
        )}
      </div>
    </div>
  );
};

export { Terminal };
