import { Project, PageAndSortParams, Job, Platform } from "@app/types";
import { BuildsQueryProps } from "@app/utils/query/useBuilds";

const androidSetupStatus = (projectId: Project["id"]) => [
  "androidSetupStatus",
  projectId,
];

const myRecentJobs = ["myRecentJobs"];

const jobs = (projectId: Project["id"], params: PageAndSortParams) => [
  "jobs",
  projectId,
  params,
];

const jobLogEntries = (
  jobId: Job["id"],
  cursor: string | unknown,
  paged: "plain" | "lastItem" | "infinite"
) => ["jobLogEntries", jobId, cursor, paged];

const project = (projectId: Project["id"]) => ["project", projectId];

const projectCredentials = (
  projectId: Project["id"],
  platform: Platform | undefined,
  pageNumber: number
) => ["projectCredentials", projectId, pageNumber];

const projectProgress = (projectId: Project["id"], platform: Platform) => [
  "projectProgress",
  projectId,
  platform,
];

const projects = (params: PageAndSortParams) => ["projects", params];

const userCredentials = (pageNumber: number) => ["userCredentials", pageNumber];

const docs = (file) => ["docs", file];

export const cacheKeys = {
  self: ["self"],
  myRecentJobs,
  isSignedIn: ["isSignedIn"],
  androidSetupStatus,
  jobs,
  jobLogEntries,
  project,
  projectCredentials,
  projectProgress,
  projects,
  userCredentials,
  docs,
  builds: (props: BuildsQueryProps) => ["builds", ...Object.values(props)],
  googleStatus: ["googleStatus"],
  androidKeyTestResult: (projectId: string) => [
    "androidKeyTestResult",
    projectId,
  ],
};
