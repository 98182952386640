import React, { useState } from "react";
import {
  Section,
  Spinner,
  EmptyState,
  CredentialRow,
  StatusMessage,
} from "@app/components";
import { Project } from "@app/types";
import { useProjectCredentials } from "@app/utils/query";
import { Link } from "react-router-dom";

interface Props {
  projectId: Project["id"];
}

const GameCredentials = ({ projectId }: Props) => {
  const { data, isLoading, isSuccess } = useProjectCredentials(projectId);
  const [downloadError, setDownloadError] = useState<undefined | string>();

  if (isLoading) return <Spinner />;

  return (
    <>
      {isSuccess && data?.projectCredentials?.length === 0 && (
        <EmptyState message="No credentials found" className="_mb15">
          <div>
            Create some like{" "}
            <Link
              className="emptyState-action"
              to="/docs/tutorial/setting-up-shipthis#creating-a-shipthis-account"
            >
              this
            </Link>
          </div>
        </EmptyState>
      )}

      <Section>
        {downloadError && (
          <StatusMessage type="success" className="_mb10">
            {downloadError}
          </StatusMessage>
        )}
        {data?.projectCredentials?.map((cred) => (
          <CredentialRow
            owner="project"
            credential={cred}
            onDownloadError={setDownloadError}
          />
        ))}
      </Section>
    </>
  );
};

export { GameCredentials };
