import React, { useEffect, useRef, useState } from "react";
import CryptoJS from "crypto-js";

import {
  isSignedIn,
  RequestOTPParams,
  SubmitOTPParams,
  useIsSignedInMutation,
} from "@app/utils/query";
import { Button, Logo, StatusMessage } from "@app/components";
import { getErrorMessage } from "@app/utils/errors";
import { Footer, Splash } from "../_Shared";
import { PAGES } from "../../../constants";
import { MetaData } from "../../Homepage/_Shared";

enum LoginStep {
  ENTER_EMAIL,
  ENTER_OTP,
}

interface AutoSubmitURLParams {
  key: string;
  token: string;
  destination?: string;
}

export const Login = () => {
  const loginMutation = useIsSignedInMutation();

  const [step, setStep] = useState<LoginStep>(LoginStep.ENTER_EMAIL);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState<undefined | string>();
  const [redirect, setRedirect] = useState<string>();

  const submitEmail = async (email) => {
    try {
      const params: RequestOTPParams = { email };
      await loginMutation.mutateAsync(params);
      setStep(LoginStep.ENTER_OTP);
      setError(undefined); // Clear for the next step
    } catch (error) {
      console.warn(error);
      setError(getErrorMessage(error));
    }
  };

  const submitOTP = async () => {
    try {
      const params: SubmitOTPParams = { email, otp: code };
      const selfWithJWT = await loginMutation.mutateAsync(params);
      // If the user has not accepted the terms, redirect them to the agreement page
      const destination = !selfWithJWT.details?.hasAcceptedTerms
        ? "/agreement"
        : redirect || "/dashboard";
      window.location.href = destination;
    } catch (error) {
      console.warn(error);
      setError(getErrorMessage(error));
    }
  };

  // The cli tool makes links which contain an encrypted email to auto submit the login form
  const handlePageLoad = async () => {
    const queryString = window.location.search;
    const query = Object.fromEntries(
      new URLSearchParams(queryString)
    ) as any as AutoSubmitURLParams;

    // Decrypt the email in the URL
    const { key, token, destination } = query;

    if (isSignedIn()) {
      // If the user is already signed in, redirect them to the destination
      window.location.href = destination || "/dashboard";
      return;
    }
    setRedirect(destination);

    if (!key || !token) return;
    const salt = "Na (s) + 1/2 Cl₂ (g) → NaCl (s)";
    const fullKey = `${key}${salt}`;
    const bytes = CryptoJS.AES.decrypt(token, fullKey);
    const email = bytes.toString(CryptoJS.enc.Utf8);
    if (!email) return;
    // Submit the request for the OTP
    setEmail(email);
    submitEmail(email);
  };

  // Hack to prevent double http request in dev
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      handlePageLoad().then(() => {});
    }
  }, []);

  const handleSubmit = () => {
    setError(undefined);
    if (step === LoginStep.ENTER_OTP) {
      submitOTP();
    } else if (step === LoginStep.ENTER_EMAIL) {
      submitEmail(email);
    }
  };

  return (
    <Splash as="main">
      <MetaData page={PAGES.Auth} />
      <>
        <form
          className="auth"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="_flex _flex-center _flex-spaceBetween">
            <Logo size="sm" />
            <h1 className="auth_h1">Login</h1>
          </div>

          {error && (
            <StatusMessage className="auth_error">{error}</StatusMessage>
          )}

          {step === LoginStep.ENTER_EMAIL && (
            <>
              <p className="auth_p">
                We will send you a one-time signin code to your email.
              </p>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="auth_input"
              />
            </>
          )}
          {step === LoginStep.ENTER_OTP && (
            <>
              <p className="auth_p">Please enter the code we sent you.</p>
              <input
                type="number"
                name="number"
                placeholder="Enter code."
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="auth_input"
              />
            </>
          )}

          <Button
            size="md"
            onClick={handleSubmit}
            centeredLoader
            isLoading={loginMutation.isPending}
          >
            Login
          </Button>
          <input type="submit" hidden />
        </form>
      </>
      <Footer />
    </Splash>
  );
};
