import axios, { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { API_URL, cacheKeys } from "@app/constants";
import { castArrayObjectDates } from "@app/utils/dates";
import { CredentialsType, Platform, ProjectCredential } from "@app/types";

import { getAuthHeaders } from "./useIsSignedIn";

export interface ProjectCredentialsQueryResult {
  projectCredentials: ProjectCredential[];
  pageCount: number;
}

export async function fetchProjectCredentials(
  projectId: string,
  pageNumber: number = 0
): Promise<ProjectCredentialsQueryResult> {
  try {
    if (!projectId) throw new Error("projectId is required");
    const headers = getAuthHeaders();
    const url = `${API_URL}/projects/${projectId}/credentials`;
    const { data } = await axios.get(url, { headers, params: { pageNumber } });
    return {
      projectCredentials: castArrayObjectDates<ProjectCredential>(data.data),
      pageCount: data.pageCount,
    };
  } catch (error) {
    console.warn("fetchProjectCredentials Error", error);
    throw error;
  }
}

export const useProjectCredentials = (
  projectId: string,
  platform?: Platform,
  pageNumber: number = 0,
  ...props: any
): UseQueryResult<ProjectCredentialsQueryResult> => {
  return useQuery<ProjectCredentialsQueryResult, AxiosError>({
    queryKey: cacheKeys.projectCredentials(projectId, platform, pageNumber),
    queryFn: () => fetchProjectCredentials(projectId, pageNumber),
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60,
    select: (data) => {
      if (!platform) return data;
      const projectCredentials = data.projectCredentials?.filter(
        (c) => c.platform === platform
      );
      return { ...data, projectCredentials };
    },
    ...props,
  });
};

export const useHasServiceAccountKey = (projectId: string) => {
  const { data, isSuccess } = useProjectCredentials(
    projectId,
    Platform.ANDROID
  );
  return (
    isSuccess &&
    data.projectCredentials.some(
      (cred) =>
        cred.isActive &&
        cred.platform === Platform.ANDROID &&
        cred.type == CredentialsType.KEY
    )
  );
};

export const useHasKeystore = (projectId: string) => {
  const { data, isSuccess } = useProjectCredentials(
    projectId,
    Platform.ANDROID
  );
  return (
    isSuccess &&
    data.projectCredentials.some(
      (cred) =>
        cred.isActive &&
        cred.platform === Platform.ANDROID &&
        cred.type == CredentialsType.CERTIFICATE
    )
  );
};
