import { useEffect } from "react";
import { io } from "socket.io-client";

import { cacheKeys, WS_URL } from "@app/constants";

import { queryClient, getCurrentJWT, JobsQueryResult } from "@app/utils/query";
import { arrayToDictionary, dictionaryToArray } from "@app/utils/dictionary";
import { Job, JobLogEntry } from "@app/types";
import { castObjectDates } from "../dates";

export interface WebSocketListener {
  // What we listen to
  getPattern: () => string | string[];
  // What we run
  eventHandler: (pattern: string, data: any) => Promise<void>;
  // Some meta-data
  id?: string;
}
export class JobLogListener implements WebSocketListener {
  constructor(private projectId: string, private jobId: string) {}
  getPattern() {
    return `project.${this.projectId}:job.${this.jobId}:log`;
  }
  async eventHandler(pattern: string, rawLogEntry: any) {
    // Update the react-query cache with the new Job Logs
    const jobId = this.jobId;
    const key = cacheKeys.jobLogEntries(
      jobId,
      rawLogEntry.nextCursor,
      "infinite"
    );

    const logEntry = castObjectDates<JobLogEntry>(rawLogEntry, [
      "sentAt",
      "createdAt",
    ]);

    queryClient.setQueryData(key, (oldData: any) => {
      if (!oldData) return oldData; // Not fetched?
      // Add new log entry to first page if not already there
      const firstPage = oldData.pages[0];
      const otherPages = oldData.pages.slice(1);
      const pageById = arrayToDictionary(firstPage);
      if (Object.keys(pageById).includes(logEntry.id)) return oldData;
      return {
        ...oldData,
        pages: [[logEntry, ...firstPage], ...otherPages],
      };
    });
  }
}

export class JobListener implements WebSocketListener {
  constructor(private projectId: string) {}
  getPattern() {
    return [
      `project.${this.projectId}:job:created`,
      `project.${this.projectId}:job:updated`,
    ];
  }
  async eventHandler(pattern: string, rawJob: Job) {
    // Update the react-query cache with the new / updated Job
    const projectId = this.projectId;

    const job = castObjectDates<Job>(rawJob);

    // When a job is created
    const isAdd = pattern.includes("created");
    if (isAdd) {
      const key = cacheKeys.jobs(projectId, { pageNumber: 0 });
      queryClient.setQueryData(key, (oldData?: JobsQueryResult) => {
        if (!oldData) return oldData; // page not fetched
        const pageById = arrayToDictionary(oldData.jobs);
        // Add if not already there
        if (Object.keys(pageById).includes(job.id)) return oldData;
        return {
          ...oldData,
          // we assume that the sort is by createdAt desc
          jobs: [job, ...oldData.jobs],
        };
      });
      return;
    }

    // When a job is updated - we will only update the first 2 pages of jobs
    for (let page = 0; page < 2; page++) {
      const key = cacheKeys.jobs(projectId, { pageNumber: page });
      queryClient.setQueryData(key, (oldData?: JobsQueryResult) => {
        if (!oldData) return oldData; // page not fetched
        const pageById = arrayToDictionary(oldData.jobs);
        // Update the job in the cache (if it exists)
        if (!Object.keys(pageById).includes(job.id)) return oldData;
        const updatedPageById = { ...pageById, [job.id]: job };
        // TODO: does this affect sort? need to re-sort?
        const updatedPage = dictionaryToArray(updatedPageById);
        return {
          ...oldData,
          jobs: updatedPage,
        };
      });
    }
  }
}

export function useWebSocket(listeners: WebSocketListener[] = []) {
  const log = console.debug;

  // TODO: this reconnects too often - use a ref for the socket/listeners?
  useEffect(() => {
    if (listeners.length === 0) {
      log("Not subscribing to WebSocket - no listeners");
      return;
    }

    if (!listeners[0]) {
      log("Not subscribing to WebSocket - invalid listener");
      return;
    }

    const token = getCurrentJWT();
    const socket = io(WS_URL, {
      auth: { token },
      forceNew: true,
    });

    socket.on("connect", () => log("Connected to WebSocket"));

    for (const listener of listeners) {
      const pattern = listener.getPattern();
      const bindSocket = (pattern: string) => {
        const boundListener = listener.eventHandler.bind(listener, pattern);
        log("Subscribing to", pattern);
        socket.on(pattern, boundListener);
      };
      if (Array.isArray(pattern)) return pattern.forEach(bindSocket);
      bindSocket(pattern);
    }

    return () => {
      log("Disconnecting from WebSocket");
      socket.disconnect();
    };
  }, [listeners]);
}
