import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { wait } from "@app/utils/wait";
import { TimeProps } from "./Span";

interface Props extends TimeProps {
  className?: string;
  isActive?: boolean;
  onFinish?: () => void;
}

const Progress = ({
  className,
  isActive,
  onFinish,
  preDelay = 0,
  typeDelay = 30,
  postDelay = 0
}: Props) => {
  const [text, setText] = useState("");

  const showProgress = async () => {
    const progressLength = 20;
    const progressChar = "█";
    const chars = progressChar.repeat(progressLength);
    const progressPercent = 100;

    const runIt = async () => {
      for (let i = 1; i < chars.length + 1; i++) {
        const percent = Math.round((i / chars.length) * 100);
        const text = `${chars.slice(0, i)} ${percent}%`;
        setText(text);

        if (typeDelay > 0) await wait(typeDelay);
        if (percent > progressPercent) break;
      }
    };

    await wait(preDelay);
    await runIt();
    await wait(postDelay);
    if (typeof onFinish === "function") onFinish();
  };

  useEffect(() => {
    if (isActive) showProgress();
  }, [isActive]);

  return <span className={classNames("terminal-span", className)}>{text}</span>;
};

export { Progress };
